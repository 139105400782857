import { Collapse } from 'bootstrap';

(function(code){
	code(window.jQuery,window,document);
}(function($,window,document){

	$(document).on ('click', '.main__header__search_toggler', function(e){
		$('.search-form').toggleClass('open');
	});

  $(document).on ('click', '.main__header__menu_toggler', function(e){
    $('body').toggleClass('menu-is-open');
    $('.sitecover').toggleClass('cover-active');
    $('.main__header__menu').slideToggle();
  });

	$(document).on ('click', '.filters-toggler', function(e){
    $('.filters').toggleClass('open');
    $('.js-wba-filter').toggleClass('open');
		$("html").toggleClass("overflow-hidden");
  });


	$(document).on('click', '.sitecover.cover-active', function(e) {
		if (!$('body').hasClass('menu-is-open')) return;
		$('body').toggleClass('menu-is-open');
		$('.sitecover').toggleClass('cover-active');
    $('.main__header__menu').slideToggle();
	})

	let owlThree = $('.owl-carousel--three_columns');

	owlThree.owlCarousel({
		loop:true,
		margin:30,
		nav: false,
		navText : ['<i class="fa-solid fa-angle-left"></i>','<i class="fa-solid fa-angle-right"></i>'],
		dots: true,
		center: false,
		autoplay: false,
		responsive:{
				0:{
					items:1
				},
				768:{
					items:3
				}
		}
	});

  let owlOne = $('.owl-carousel--one_column');

	owlOne.owlCarousel({
		loop: true,
		margin: 30,
		nav: false,
		navText : ['<i class="fa-solid fa-angle-left"></i>','<i class="fa-solid fa-angle-right"></i>'],
		dots: true,
		center: false,
		autoplay: false,
		responsive:{
				0:{
					items:1
				},
				768:{
					items:1
				}
		}
	});

	$('.owl-carousel').each(function() {
	    //Find each set of dots in this carousel
	  $(this).find('.owl-dot').each(function(index) {
	    //Add one to index so it starts from 1
	    $(this).attr('aria-label', index + 1);
	  });
	});

	const video_wrapper = jQuery('.video-iframe');
	const video_thumbnail = jQuery('.video-thumbnail');
	//  Check to see if youtube wrapper exists
	if(video_wrapper.length && video_thumbnail){
		// If user clicks on the video wrapper load the video.
		video_thumbnail.on('click', function(){
			video_wrapper.removeClass('hidden');

			video_thumbnail.addClass('hidden');

			/* Dynamically inject the iframe on demand of the user.
			Pull the youtube url from the data attribute on the wrapper element. */
			video_wrapper.html('<iframe allow="autoplay" frameborder="0" class="embed-responsive-item" src="' + video_wrapper.data('yt-url') + '"></iframe>');
		});
	}


	let prevWindowWidth = $(window).width();
	// Handle which image should displayed on the banner slider.
	function replaceBackgroundImage() {
			const mobileWidth = 600;
	    const windowWidth = $(window).width();

	    if (windowWidth <= mobileWidth && (prevWindowWidth === windowWidth || prevWindowWidth > mobileWidth)) {
	        // Switched to mobile view or it's the initial load below 600px
	        $('.js--banner-image').each(function () {
	            const mobileImage = $(this).data('mobile-image');
	            if (mobileImage) {
	                // Store the original desktop image
	                $(this).data('desktop-image', $(this).css('background-image'));
	                // Set the mobile image for smaller screens
	                $(this).css('background-image', 'url(' + mobileImage + ')');
	            }
	        });
	    } else if (windowWidth > mobileWidth && (prevWindowWidth === windowWidth || prevWindowWidth <= mobileWidth)) {
	        // Switched to desktop view or it's the initial load above 600px
	        $('.js--banner-image').each(function () {
	            // Reset to the original desktop image for larger screens
	            const desktopImage = $(this).data('desktop-image');
	            $(this).css('background-image', desktopImage);
	        });
	    }

	    // Update the previous window width
	    prevWindowWidth = windowWidth;
	}

	// Initial call to set the background images
	replaceBackgroundImage();

	// Attach the function to the window resize event
	$(window).resize(replaceBackgroundImage);

	// Get the img element by its ID, class, or any other selector
	const imgElement = $('img.attachment-post-thumbnail');

	// Check if the element has the --dominant-color style attribute
	if (imgElement.length > 0 && imgElement.css('--dominant-color')) {
	    // Remove the --dominant-color style attribute
	    imgElement.css('--dominant-color', '');
	}
}));
